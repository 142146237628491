<template>
  <div v-if="styled != 'menu'" class="locale-changer">
    <v-menu v-if="styled != 'menu'" transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }">
              {{ getFlagSymbol($i18n.locale.slice(-2).toLowerCase()) }}
              <v-icon right dark> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("language") }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, indx) in langs"
          :key="indx"
          link
          @click="(e) => langChanged(item, e)"
        >
          <v-list-item-icon>
            <v-icon
              v-text="getFlagSymbol(item.slice(-2).toLowerCase())"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="getLanguageNativeName(item.slice(-2).toLowerCase())"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>

  <v-list v-else-if="styled == 'menu'" flat class="pt-4" nav dense>
    <v-subheader>{{ $t("language") }}</v-subheader>
    <v-list-item-group v-model="item">
      <v-list-item
        v-for="(item, indx) in langs"
        :key="indx"
        link
        @click="(e) => langChanged(item, e)"
      >
        <v-list-item-icon>
          <v-icon v-text="getFlagSymbol(item.slice(-2).toLowerCase())"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            v-text="getLanguageNativeName(item.slice(-2).toLowerCase())"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "LocaleChanger.vue",
  props: {
    styled: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      langs: Object.keys(this.$i18n.messages),
      item: 1,
    };
  },
  methods: {
    langChanged: function (lang, e) {
      localStorage.Lang = lang;
      this.$i18n.locale = lang;

      this.$emit("click", e);
    },
    getFlagSymbol(loc) {
      switch (loc) {
        case "sk":
          return "🇸🇰";
        case "ua":
          return "🇺🇦";
        case "pl":
          return "🇵🇱";
        case "de":
          return "🇩🇪";
        case "fi":
          return "🇫🇮";
        case "cz":
          return "🇨🇿";
        case "hu":
          return "🇭🇺";
        case "bg":
          return "🇧🇬";
        case "ro":
          return "🇷🇴";
        case "lv":
          return "🇱🇻";
        case "lt":
          return "🇱🇹";
        case "sl":
          return "🇸🇮";
        case "hr":
          return "🇭🇷";
        case "it":
          return "🇮🇹";
        case "fr":
          return "🇫🇷";
        case "es":
          return "🇪🇸";
        case "gr":
          return "🇬🇷";
        case "pt":
          return "🇵🇹";
        case "ee":
          return "🇪🇪";
        case "dk":
          return "🇩🇰";
        case "se":
          return "🇸🇪";
        case "no":
          return "🇳🇴";
        case "tr":
          return "🇹🇷";
        case "il":
          return "🇮🇱";
        default:
          return "🇬🇧";
      }
    },
    getLanguageNativeName(loc) {
      switch (loc) {
        case "sk":
          return "Slovenčina";
        case "ua":
          return "Українська";
        case "pl":
          return "Polski";
        case "de":
          return "Deutsch";
        case "fi":
          return "Suomi";
        case "cz":
          return "Čeština";
        case "hu":
          return "Magyar";
        case "bg":
          return "Български";
        case "ro":
          return "Limba română";
        case "lv":
          return "Latviešu valoda";
        case "lt":
          return "Lietuvių kalba";
        case "sl":
          return "Slovenščina";
        case "hr":
          return "Hrvatski";
        case "it":
          return "Italiano";
        case "fr":
          return "Français";
        case "es":
          return "Español";
        case "gr":
          return "Eλληνικά";
        case "pt":
          return "Português";
        case "ee":
          return "Eesti keel";
        case "dk":
          return "Dansk";
        case "se":
          return "Svenska";
        case "no":
          return "Norsk";
        case "tr":
          return "Türkçe";
        case "il":
          return "עברית";
        default:
          return "English";
      }
    },
    formatLocaleItem(item, full) {
      const loc = item.slice(-2).toLowerCase();
      return `${this.getFlagSymbol(loc)} ${
        full ? this.getLanguageNativeName(loc) : loc
      }`;
    },
  },
  mounted() {
    if (localStorage.Lang != null) this.$i18n.locale = localStorage.Lang;
  },
};
</script>

<style scoped>
</style>
