<template>
    <v-hover v-slot:default="{ hover }" style="cursor: pointer;">
        <v-card :flat="flat" class="text-center pa-3 offer-card" :color="hover ? 'grey lighten-3' : 'white'" @click="$router.push({name: 'WebAppEventDetail', params: {id: event.ID_offer}})">
            <div :style="$vuetify.breakpoint.smAndDown ? 'position: relative; text-align: right; margin-right: 10px;' : 'position: absolute; top: 10%; right: 5%;'">
            <v-tooltip right v-if="event.linkedUsers >= event.demand">
                <template v-slot:activator="{ on }">
                    <span v-on="on">
                        <v-icon class="red--text">person_add_disabled</v-icon>
                    </span>
                </template>
                <div style="width: 200px;">{{$t("eventCard.offerFull")}}</div>
            </v-tooltip>
            <v-tooltip right v-else>
                <template v-slot:activator="{ on }">
                    <span v-on="on">
                        <v-icon class="green--text">person_add</v-icon>
                    </span>
                </template>
                <div style="width: 200px;">{{ $t("eventCard.freePlaces") }}</div>
            </v-tooltip>
            </div>
            <v-row>
                <v-col cols=12 md=6 :class="$vuetify.breakpoint.smAndDown ? 'pt-1' : 'pt-4'">
                    <div :class="[$vuetify.breakpoint.smAndDown ? 'headline' : 'headline', 'font-weight-medium', 'primary--text']">{{ event.title }}</div>
                    <div :class="[$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-10', 'mt-2', 'subtitle-1', 'text-justify']">
                    {{ stripText(event.desc, 180) }}
                    </div>
                </v-col>
                <v-col cols=12 md=6>
                    <v-row justify="start" class="px-5 my-2 subtitle-1" wrap>
                        <v-col cols=12 class="text-left pa-0 py-1" v-if="event.isProtected">
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <v-icon class="primary--text mr-2" small>lock</v-icon>
                                        <span class="primary--text font-weight-bold">{{ $t("offer.protected") }}</span>
                                    </span>
                                </template>
                                <div style="width: 200px;">{{ $t("eventCard.protectedTooltip") }}</div>
                            </v-tooltip>
                        </v-col>
                        <v-col cols=12 class="text-left pa-0 py-1">
                            <v-icon class="primary--text mr-2" small>near_me</v-icon>
                            <span >{{findRegionNameByCode(event.region)}}</span>
                        </v-col>
                        <v-col cols=12 class="text-left pa-0 py-1 ">
                            <v-icon class="primary--text mr-2" small>calendar_today</v-icon>
                            <span v-if="event.type == 4">{{ $t("offer.longTerm") }}</span>
                            <span v-else-if="event.endDate && event.endDate != event.date">{{formatDate(event.nextEventDate)}} - {{formatDate(event.endDate)}}</span>
                            <span v-else-if="event.type != 1 && event.type != 2">{{formatDate(event.nextEventDate)}} <span class="grey--text text--lighten-1">|</span> {{ event.time }} - {{ event.endTime }}</span>
                            <span v-else>{{formatDate(event.nextEventDate)}}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4"></v-divider>
                    <v-row justify="start" class="px-5 my-2" no-gutters>
                        <v-col cols=12 class="text-left">
                            <span v-for="(tag, index) in event.tags" :key="index">
                                <Tag v-if="tag" :name="tag" class="mt-2" />
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-hover>
</template>

<script>
import Tag from '@/components/partials/Tag'
import utilitiesMixin from '@/mixins/utilitiesMixin'
    export default {
        props : {
            event: {
                type: Object,
                required: true
            },
            showActions: {
                type: Boolean,
                default: true
            },
            publicMode: {
                type: Boolean,
                default: false
            },
            flat: {
                type: Boolean,
                default: true
            }
        },
        mixins: [utilitiesMixin],
        components: {Tag},
    }
</script>

<style lang="scss" scoped>

</style>