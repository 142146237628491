<template>
  <div :class="['viewmore-button', { show }]">
    <v-btn elevation="2" fab x-large :ripple="false" icon @click="scrollDown">
      <img
        src="@/assets/landing/chevron-circle-down-solid.svg"
        alt=".."
        width="72"
      />
    </v-btn>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "ViewMoreButton",
  data() {
    return {
      show: true,
      devicePixelRatio: {
        current: 0,
        intervalId: null,
      },
      handlePageChange: null,
    };
  },
  methods: {
    scrollOnePageAnimated(callback) {
      const pageHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        0;

      window.scrollTo({ top: window.scrollY + pageHeight, behavior: "smooth" });

      callback();
    },
    processPageChange() {
      const pageHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        0;

      const browserHeight = document.body.getBoundingClientRect().height;

      this.show =
        window.scrollY + pageHeight <
        browserHeight -
          100 /* a minimal distance remaining to a bottom edge of a page to hide the button */;
    },
    scrollDown(e) {
      this.scrollOnePageAnimated(() => e.path[2].blur());
    },
  },
  created: function () {
    this.handlePageChange = debounce(this.processPageChange, 500);

    window.addEventListener("scroll", this.handlePageChange);
    window.addEventListener("resize", this.handlePageChange);
    document.addEventListener("load", this.handlePageChange);

    const self = this;

    this.devicePixelRatio.intervalId = window.setInterval(() => {
      const dpr = window.devicePixelRatio;
      if (self.devicePixelRatio.current !== dpr) {
        self.devicePixelRatio.current = dpr;

        self.handlePageChange(dpr);
      }
    }, 500);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.handlePageChange);
    window.removeEventListener("resize", this.handlePageChange);
    document.removeEventListener("load", this.handlePageChange);

    if (this.devicePixelRatio.intervalId) {
      window.clearInterval(this.devicePixelRatio.intervalId);
      this.devicePixelRatio.intervalId = null;
    }
  },
};
</script>

<style scoped>
.viewmore-button {
  z-index: 222;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 3rem;
  margin-bottom: 3rem;
  transition: opacity 0.5s ease, transform 0.25s ease;
  opacity: 0 !important;
  visibility: hidden !important;
}

@media (max-width: 767.99px) {
  .viewmore-button {
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.viewmore-button.show {
  opacity: 1 !important;
  visibility: visible !important;
}
</style>