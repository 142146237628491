<template>
<div class="landing">
      <v-row>
        <v-col cols=6 md=4 lg=3 class="pl-8 pt-10">
          <LocaleChanger />
        </v-col>
        <v-spacer />
        <v-col class="pr-10 pt-10">
          <router-link tag="span" :to="{name: 'login'}">
            <div class="landing_login mt-5">
              {{ $t("navbar.login") }}
            </div>
          </router-link>
        </v-col>
      </v-row>
      <div class="s1_section section">
        <div class="title_row">
          <img src="@/assets/landing/logo_landing.svg" />
          <span class="title_row_title">
            {{ $t("landing.title") }}
          </span>
        </div>
        <div class="subtitle_row">
          <div class="subtitle_row_text1">{{ $t("landing.subtitle") }}</div>
          <div class="subtitle_row_text2">{{ $t("landing.wantToHelp") }}</div>
          <router-link tag="span" :to="{name: 'WebAppEventHome'}"><div class="landing_button">{{ $t("landing.seeOffers") }}</div></router-link>
        </div>
      </div>
      <div class="s2_section section">
        <img src="@/assets/landing/first_wave_top.svg" class="s1_wave_top" />
        <img src="@/assets/landing/first_wave_bottom.svg" class="s1_wave_bottom" />
        <div class="s2_section_content">
        <div class="s2_section_title">{{ $t("landing.howToHelp.title") }}</div>
        <v-row class="mt-10 mx-10">
          <v-col cols=12 sm=4 class="text-center px-5 white--text">
            <img class="s2_section_img mt-10" src="@/assets/landing/mobil.svg"/>
            <div :class="['s2_section_text', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-10']">{{ $t("landing.howToHelp.register") }}</div></v-col>
          <v-col cols=12 sm=4 class="text-center px-5 white--text">
            <img :class="['s2_section_img mt-10', $vuetify.breakpoint.smAndDown ? 'mt-10' : '']" src="@/assets/landing/kalendar.svg"/>
            <div :class="['s2_section_text', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-10']">{{ $t("landing.howToHelp.choose") }}</div></v-col>
          <v-col cols=12 sm=4 class="text-center px-5 white--text">
            <img :class="['s2_section_img mt-10', $vuetify.breakpoint.smAndDown ? 'mt-10' : '']" src="@/assets/landing/ruky.svg"/>
            <div :class="['s2_section_text', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-10']">{{ $t("landing.howToHelp.help") }}</div></v-col>
        </v-row>
        <div class="s2_section_action">
          <router-link tag="span" :to="{name: 'register'}"><div class="s2_section_action_button">{{ $t("landing.iWantToRegister") }}</div></router-link>
        </div>
        </div>
      </div>
      <div class="s3_section section">
        <v-row class="mt-10 py-10">
          <v-col cols=12 md=8 :class="['text-center', $vuetify.breakpoint.smAndDown ? 'px-1' : 'px-10']">
            <div class="s3_section_title">
              {{ $t("landing.whatWeDo.title") }}
            </div>
            <p class="s3_section_text" v-html="$t('landing.whatWeDo.text', { helfni: '<strong>Helfni</strong>' })" />
            <div class="s3_section_title">
              {{ $t("landing.whoWeAre.title") }}
            </div>
            <p class="s3_section_text" v-html="$t('landing.whoWeAre.text', { kajo: '<a href=https://kajoservices.com target=blank>kajoservices.com</a>' })" />
          </v-col>
        </v-row>
        <img v-if="!$vuetify.breakpoint.smAndDown" src="@/assets/landing/ziarovka_third.svg" class="s3_section_img" />
      </div>
      <div class="s4_section section">
        <img src="@/assets/landing/second_wave_top.svg" class="s2_wave_top" />
        <img src="@/assets/landing/second_wave_bottom.svg" class="s2_wave_bottom" />
        <div class="s4_section_content">
          <div class="s4_section_title">{{ $t("landing.numbers.title") }}</div>
        <v-row class="mt-10 mx-10">
          <v-col cols=12 sm=4 class="text-center px-5 white--text">
            <img :class="['s2_section_img', 'mt-10']" src="@/assets/landing/dobrovolnici.svg"/>
            <div :class="['display-3', 'font-weight-bold', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-10']">{{statistics.users}}</div>
            <div class="s2_section_text">{{ $t("landing.numbers.volunteers") }}</div></v-col>
          <v-col cols=12 sm=4 class="text-center px-5 white--text">
            <img :class="['s2_section_img', $vuetify.breakpoint.smAndDown ? 'mt-10' : '']" src="@/assets/landing/organizacie.svg"/>
            <div :class="['display-3', 'font-weight-bold', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-10']">{{statistics.organizations}}</div>
            <div class="s2_section_text">{{ $t("landing.numbers.organisations") }}</div></v-col>
          <v-col cols=12 sm=4 class="text-center px-5 white--text">
            <img :class="['s2_section_img', $vuetify.breakpoint.smAndDown ? 'mt-10' : '']" src="@/assets/landing/ponuky.svg"/>
            <div :class="['display-3', 'font-weight-bold', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-10']">{{events.length}}</div>
            <div class="s2_section_text">{{ $t("landing.numbers.offers") }}</div></v-col>
        </v-row>
        </div>
      </div>
      <div class="s5_section">
        <div class="s5_section_title">
          {{ $t("landing.offerList.title") }}
        </div>
        <div class="s5_section_content">
          <div v-if="getRandom(events, 3).length > 0">
            <EventCard
              class="mt-5"
              v-for="(event, index) in getRandom(events, Math.min(events.length, 3))"
              :key="index"
              :event="event"
              :showActions="true"
              :flat="false"
            />
          </div>
          <div v-else>
            {{ $t("landing.offerList.notFound") }}
          </div>
        <div class="s5_section_text">
          <router-link tag="span" :to="{name: 'WebAppEventHome'}"><v-icon class="primary--text mr-1" x-large>expand_more</v-icon>{{ $t("landing.offerList.showMore") }}</router-link>
        </div>
        </div>
      </div>
      <div class="s6_section section">
        <div :class="['s5_section_title', $vuetify.breakpoint.smAndDown ? 'mx-2' : '']">
          {{ $t("landing.map.title") }}
        </div>
        <div class="s6_section_content">
          <img src="@/assets/landing/mapa.svg" />
          <div class="s6_section_text">
            <router-link tag="span" :to="{name: 'WebAppMap'}"><v-icon class="primary--text mr-1" x-large>expand_more</v-icon>{{ $t("landing.map.showOnMap") }}</router-link>
          </div>
        </div>
      </div>
      <div class="s7_section">
        <img src="@/assets/landing/third_wave_top.svg" class="s3_wave_top" />
        <img src="@/assets/landing/third_wave_bottom.svg" class="s3_wave_bottom" />
        <div class="s7_section_content">
          <div class="s7_section_text">{{ $t("landing.motto") }}</div>
        </div>
      </div>
      <div class="s8_section section">
        <v-row class="mt-10 pt-10">
          <v-col cols=12 md=8 class="text-center px-10">
            <div class="s8_section_title">
              {{ $t("landing.partners") }}
            </div>
            <div class="mt-10 pl-md-10 d-flex flex-column flex-lg-row justify-start align-center align-md-start">
              <div class="mb-8 mb-lg-0 mr-lg-10">
                <a href="https://www.kajogeoservices.com/" target="_blank"><img class="logo_org" src="@/assets/landing/kajo-logo_web.svg" ></a>
              </div>
              <div class="mb-8 mb-lg-0 mr-lg-10 align-self-lg-center">
                <a href="http://sdisk.eu" target="_blank"><img class="logo_org2" src="@/assets/landing/sdi_logo.jpg"></a>
              </div>
              <div class="mb-8 mb-lg-0 mr-lg-10 align-self-lg-center">
                <a href="https://greenfoundation.eu" target="_blank"><img class="logo_org2" src="@/assets/landing/Greenfoundation.png"></a>
              </div>
            </div>
          </v-col>
        </v-row>
        <img v-if="!$vuetify.breakpoint.smAndDown"  src="@/assets/landing/dom.svg" class="s8_section_img" />
      </div>
      <div class="s9_section">
        <div class="s9_section_text">
          {{ $t("landing.footer.socialMedia") }}
        </div>
        <a href="https://www.facebook.com/pages/category/Product-Service/Helfni-102463494599655/" target="_blank" class="s9_section_link">
          Facebook
        </a>
        <a href="https://www.instagram.com/helfni.slovensku/" target="_blank" class="s9_section_link">
          Instagram
        </a>
        <div class="caption mt-10 white--text mx-3 text-center">
          {{ $t("landing.footer.disclaimer") }}
        </div>
        <div class="caption white--text mb-3 mt-2">
          Copyright &copy; HELFNI {{ currentYear() }}
        </div>
      </div>
  <ViewMoreButton />
</div>
</template>

<script>
import axios from 'axios'
import EventCard from '@/components/events/EventCard'
import LocaleChanger from '@/components/partials/LocaleChanger'
import ViewMoreButton from '@/components/partials/ViewMoreButton'

  export default {
    name: 'Landing',
    components: {EventCard, LocaleChanger, ViewMoreButton},
    data() {
      return {
        events: [],
        statistics: {organizations: 0, users: 0, offers: 0},
        isLandscapeBool: false
      }
    },
    methods: {
      currentYear() {
        const d = new Date()
        return d.getFullYear()
      },
      isLandscape() {
        if (window.innerWidth > window.innerHeight) {
          return this.isLandscapeBool = true
        }
        else {
          return this.isLandscapeBool = false
        }
      },
      getRandom(arr, n) {
        if (arr.length > 0) {
          var result = new Array(n),
              len = arr.length,
              taken = new Array(len);
          if (n > len)
              n = len
          while (n--) {
              var x = Math.floor(Math.random() * len);
              result[n] = arr[x in taken ? taken[x] : x];
              taken[x] = --len in taken ? taken[len] : len;
          }
          return result;
        }
        else {
          return []
        }
      },
      getEventData() {
        axios.post('/get_offers', {onlyActive: 1})
        .then(response => {
            this.getEventContent(response.data.offers)
        })
        .catch(() => {

        })
      },
      getEventContent(ids) {
        axios.post('/get_offers_info', {ID_offers: ids})
        .then(response => {
            response.data.info.forEach(element => {
                if (element.isActive &&  (element.type == 4 || new Date(`${element.nextEventDate}T${element.nextEventTime || "00:00"}:00`) >= Date.now()))
                    this.events.push({
                      ... element,
                      tags: (element.tags || []).map(tag => {
                        if(!this.$store.state.tags[tag]){
                          const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                          const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                          return key;
                        } else {
                          return tag;
                        }
                      })
                    })
                });
        })
        .catch(() => {

        })
      },
      getNumbers() {
        axios.get('/get_numbers')
        .then(response => {
          this.statistics = response.data
        })
      }
    },
    created() {
      this.getEventData()
      this.getNumbers()
      this.isLandscape()
      window.addEventListener('resize', this.isLandscape)
    },
    destroyed() {
      window.removeEventListener("resize", this.isLandscape);
    }
  }
</script>
<style scoped>
.landing_login {
  position: absolute;
  right: 15px;
  top: 15px;
  color: var(--v-primary-base);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1em;
}
.landing_login:hover{
  background-color: #FAFAFA;
}

.landing {
  background-color: white;
  overflow: hidden;
  position: relative;
}
.section {
  height: 1050px;
  width: 100%;
}
.landing_button {
  display: inline-block;
  background-color: var(--v-primary-base);
  padding: 5px 30px;
  color: white;
  font-size: 1em;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.s1_section {
  padding-top: 50px;
  height: 500px;
}
.title_row {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title_row img {
  height: 50%;
}
.title_row_title {
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 10px;
}
.subtitle_row {
  text-align: center;
}
.subtitle_row_text1 {
  font-size: 1.2em;
}
.subtitle_row_text2 {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--v-primary-base);
}

.s1_wave_top {
  width: 110%;
  position: absolute;
  left: -10%;
  top: -25vw;
  z-index: -1;
  pointer-events: none;
}

.s1_wave_bottom {
  width: 110%;
  position: absolute;
  left: -5%;
  bottom: -5vw;
  z-index: -1;
  pointer-events: none;
}

.s2_section {
  position: relative;
  z-index: 1;
  height: 900px;
}

.s2_section_content {
  background-color: #ea8031;
  height: 850px;
}

.s2_section_title {
  color: white;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}

.s2_section_img {
  height: 70px;
}

.s2_section_text {
  font-size: 1.2em;
}

.s3_section {
  height: unset !important;
  position: relative;
}

.s3_section_title {
  color: var(--v-primary-base);
  font-size: 2.5em;
  font-weight: bold;
}

.s3_section_text {
  font-size: 1em;
  text-align: justify;
  margin: 50px 30px;
  line-height: 2rem;
}

.s3_section_img {
  height: 900px;
  position: absolute;
  right: 10%;
  top: -30%;
}

.s4_section {
  position: relative;
  z-index: 1;
  height: 900px;
}

.s4_section_content {
  height: 830px;
  background-color: #ea8031;
  padding-top: 30px;
}

.s2_wave_top {
  width: 120%;
  position: absolute;
  left: -15%;
  top: -10vw;
  z-index: -1;
  pointer-events: none;
}

.s2_wave_bottom {
  width: 110%;
  position: absolute;
  left: -5%;
  bottom: 0vw;
  z-index: -1;
  pointer-events: none;
}

.s4_section_title {
  color: white;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.s5_section_title {
  color: var(--v-primary-base);
  font-size: 1.8em;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
}

.s5_section_content {
  width: 90%;
  margin: auto;
}

.s5_section_text {
  color: var(--v-primary-base);
  font-size: 1.5em;
  margin-top: 20px;
  cursor: pointer;
}

.s6_section {
  padding-top: 20px;
  height: 500px;
}

.s6_section_content {
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.s6_section img {
  width: 90%;
}

.s6_section_text {
  color: var(--v-primary-base);
  font-size: 1.5em;
  margin-top: 50px;
  cursor: pointer;
  width: 90%;
}

.s3_wave_top {
  width: 120%;
  position: absolute;
  left: -15%;
  top: -10vw;
  z-index: -1;
  pointer-events: none;
}

.s3_wave_bottom {
  width: 110%;
  position: absolute;
  left: -5%;
  bottom: -10vw;
  z-index: -1;
  pointer-events: none;
}

.s7_section {
  position: relative;
  z-index: 1;
  margin-top: 50px;
  height: 200px;
}

.s7_section_content {
  height: 100%;
  background-color: #ea8031;
  text-align: center;
  display: grid;
  place-items: center;
}

.s7_section_text {
  font-size: 1.8em;
  color: white;
  font-weight: bold;
}

.s8_section {
  position: relative;
  margin-top: 30px;
  height: auto;
}

.s8_section_title {
  font-size: 2em;
  font-weight: bold;
}

.s8_section_img {
  height: 1000px;
  position: absolute;
  right: 10%;
  top: -30%;
}

.s9_section {
  background-color: #ea8031;
  padding: 20px 0px;
  display: grid;
  place-items: center;
}

.s9_section_text {
  font-size: 2em;
  color: white;
  font-weight: bold;
  text-align: center;
}

.s9_section_link {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  text-decoration: underline !important;
}

.logo_org {
  height: 200px;
}

.logo_org2 {
  height: 150px;
}

.s2_section_action {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.s2_section_action_button {
  background-color: white;
  padding: 10px 30px;
  font-size: 1.2em;
  color: var(--v-primary-base);
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .landing_login {
    font-size: 1.5em;
    padding: 10px 20px;
    right: 20px;
    top: 20px;
  }
  .s1_section {
    padding-top: 150px;
  }
  .title_row_title {
    font-size: 3em;
    padding-bottom: 30px;
  }
  .title_row img {
    height: 60%;
  }
  .subtitle_row_text1 {
  font-size: 2em;
  }
  .subtitle_row_text2 {
    font-size: 2em;
  }
  .landing_button {
    padding: 10px 40px;
    font-size: 1.5em;
    margin-top: 20px;
    border-radius: 10px;
  }
  .s1_section {
    height: 980px;
  }
  .s2_section_title {
    font-size: 3.5em;
  }
  .s2_section_img {
    height: 170px;
  }
  .s2_section_text {
    font-size: 1.5em;
  }
  .s2_section {
    height: 980px;
  }
  .s2_section_content {
    height: 850px;
  }
  .s3_section_title {
    font-size: 3.5em;
  }
  .s3_section_text {
    font-size: 1.5em;
    margin: 50px 100px;
    line-height: 3rem;
  }
  .s4_section_content {
    height: 850px;
    padding-top: 50px;
  }
  .s4_section_title {
    font-size: 3.5em;
  }
  .s4_section {
    position: relative;
    z-index: 1;
    height: 980px;
  }
  .s5_section_content {
    width: 60%;
  }
  .s5_section_title {
    font-size: 3.5em;
    margin-left: 10%;
    margin-bottom: 50px;
    margin-top: 0;
    text-align: center;
  }
  .s6_section {
    padding-top: 100px;
    height: 980px;
  }
  .s6_section_content {
    padding-top: 50px;
  }
  .s6_section img {
    width: 80%;
  }

  .s6_section_text {
    width: 60%;
  }
  .s7_section {
    margin-top: 0px;
    height: 400px;
  }
  .s7_section_text {
    font-size: 4em;
  }
  .s3_wave_bottom {
    bottom: -5vw;
  }
  .s8_section {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .s8_section_title {
    font-size: 2.5em;
  }
  .s3_section {
  }

  .s2_section_action {
    margin-top: 100px;
  }

  .s2_section_action_button {
    padding: 10px 30px;
    font-size: 2em;
  }

  .s9_section_text {
    font-size: 3em;
  }

  .s9_section_link {
    font-size: 2.5em;
  }
}

@media only screen and (min-width: 1100px) and (orientation: landscape){
  .title_row_title {
    font-size: 5em;
  }
  .title_row img {
    height: 100%;
  }
  .s2_section_title {
    font-size: 4em;
  }
  .s2_section_text {
    font-size: 1.6em;
  }
  .s4_section_title {
    font-size: 4em;
  }
  .s5_section_title {
    text-align: left;
  }
  .s6_section img {
    width: 60%;
  }
}

@media only screen and (min-width: 1281px) {
  .s1_section {
    padding-top: 150px;
  }
  .title_row_title {
    font-size: 5em;
  }
  .title_row img {
    height: 100%;
  }
  .s1_section {
    height: 980px;
  }
  .s2_section_title {
    font-size: 4em;
  }
  .s2_section_img {
    height: 170px;
  }
  .s2_section_text {
    font-size: 1.7em;
  }
  .s2_section {
    height: 980px;
  }
  .s2_section_content {
    height: 800px;
  }
  .s3_section_title {
    font-size: 3.5em;
  }
  .s3_section_text {
    font-size: 1.5em;
    margin: 50px 100px;
    line-height: 3rem;
  }
  .s4_section_content {
    height: 650px;
    padding-top: 100px;
  }
  .s4_section_title {
    font-size: 4em;
  }
  .s4_section {
    position: relative;
    z-index: 1;
    height: 850px;
  }
  .s5_section_content {
    width: 60%;
  }
  .s5_section_title {
    font-size: 3.5em;
    margin-left: 10%;
    margin-bottom: 50px;
    margin-top: 0;
    text-align: left;
  }
  .s6_section {
    padding-top: 100px;
    height: 980px;
  }
  .s6_section_content {
    padding-top: 50px;
  }
  .s6_section img {
    width: 60%;
  }

  .s6_section_text {
    width: 60%;
  }
  .s7_section {
    margin-top: 200px;
  }
  .s7_section_text {
    font-size: 5em;
  }
  .s8_section {
    margin-top: 150px;
    height: 900px;
  }

  .s8_section_title {
    font-size: 3.5em;
  }
  .s3_section {
  }

  .s2_section_action {
      margin-top: 100px;
  }

  .s2_section_action_button {
    padding: 10px 30px;
    font-size: 2em;
  }

}

@media only screen and (min-width: 1500px) {
  .s3_section_text {
    font-size: 1.7em;
    margin: 50px 150px;
  }
  .s2_section_text {
    font-size: 1.9em;
  }
}
</style>
