var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{staticStyle:{"cursor":"pointer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center pa-3 offer-card",attrs:{"flat":_vm.flat,"color":hover ? 'grey lighten-3' : 'white'},on:{"click":function($event){return _vm.$router.push({name: 'WebAppEventDetail', params: {id: _vm.event.ID_offer}})}}},[_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown ? 'position: relative; text-align: right; margin-right: 10px;' : 'position: absolute; top: 10%; right: 5%;')},[(_vm.event.linkedUsers >= _vm.event.demand)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{staticClass:"red--text"},[_vm._v("person_add_disabled")])],1)]}}],null,true)},[_c('div',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.$t("eventCard.offerFull")))])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{staticClass:"green--text"},[_vm._v("person_add")])],1)]}}],null,true)},[_c('div',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.$t("eventCard.freePlaces")))])])],1),_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pt-1' : 'pt-4',attrs:{"cols":"12","md":"6"}},[_c('div',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'headline' : 'headline', 'font-weight-medium', 'primary--text']},[_vm._v(_vm._s(_vm.event.title))]),_c('div',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-10', 'mt-2', 'subtitle-1', 'text-justify']},[_vm._v(" "+_vm._s(_vm.stripText(_vm.event.desc, 180))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"px-5 my-2 subtitle-1",attrs:{"justify":"start","wrap":""}},[(_vm.event.isProtected)?_c('v-col',{staticClass:"text-left pa-0 py-1",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{staticClass:"primary--text mr-2",attrs:{"small":""}},[_vm._v("lock")]),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t("offer.protected")))])],1)]}}],null,true)},[_c('div',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.$t("eventCard.protectedTooltip")))])])],1):_vm._e(),_c('v-col',{staticClass:"text-left pa-0 py-1",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"primary--text mr-2",attrs:{"small":""}},[_vm._v("near_me")]),_c('span',[_vm._v(_vm._s(_vm.findRegionNameByCode(_vm.event.region)))])],1),_c('v-col',{staticClass:"text-left pa-0 py-1 ",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"primary--text mr-2",attrs:{"small":""}},[_vm._v("calendar_today")]),(_vm.event.type == 4)?_c('span',[_vm._v(_vm._s(_vm.$t("offer.longTerm")))]):(_vm.event.endDate && _vm.event.endDate != _vm.event.date)?_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.event.nextEventDate))+" - "+_vm._s(_vm.formatDate(_vm.event.endDate)))]):(_vm.event.type != 1 && _vm.event.type != 2)?_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.event.nextEventDate))+" "),_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.event.time)+" - "+_vm._s(_vm.event.endTime))]):_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.event.nextEventDate)))])],1)],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-row',{staticClass:"px-5 my-2",attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},_vm._l((_vm.event.tags),function(tag,index){return _c('span',{key:index},[(tag)?_c('Tag',{staticClass:"mt-2",attrs:{"name":tag}}):_vm._e()],1)}),0)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }