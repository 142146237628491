<template>
  <v-tooltip right v-if="tooltip" content-class="custom_tooltip">
    <template v-slot:activator="{ on }">
      <span v-on="on" style="cursor: pointer">
        <v-chip
          :style="!clickable ? 'pointer-events: none;' : ''"
          class="mr-2 my-1"
          :color="invertedColors ? 'grey lighten-4' : 'primary'"
          @click="$emit('click')"
        >
          <v-icon
            small
            class="mr-1"
            :class="
              invertedColors ? 'grey--text text--darken-2' : 'white--text'
            "
            >{{ tag.icon }}</v-icon
          >
          <span
            :class="[
              invertedColors ? 'grey--text text--darken-2' : 'white--text',
              $vuetify.breakpoint.mdAndDown ? 'caption' : '',
            ]"
            >{{ tag.name }}</span
          >
        </v-chip>
      </span>
    </template>
    <div style="width: 200px">{{ tag.description }}</div>
  </v-tooltip>

  <v-chip
    :style="!clickable ? 'pointer-events: none;' : ''"
    class="mr-2 my-1"
    :color="invertedColors ? 'grey lighten-4' : 'primary'"
    @click="$emit('click')"
    v-else
  >
    <v-icon
      small
      class="mr-1"
      :class="invertedColors ? 'grey--text text--darken-2' : 'white--text'"
      >{{ tag.icon }}</v-icon
    >
    <span
      :class="[
        invertedColors ? 'grey--text text--darken-2' : 'white--text',
        $vuetify.breakpoint.mdAndDown ? 'caption' : '',
      ]"
      >{{ tag.name }}</span
    >
  </v-chip>
</template>

<script>
export default {
  name: "Tag",
  props: {
    name: {
      type: String,
      required: true,
    },
    invertedColors: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      let tag = this.$store.state.tags[this.name];

      if (!tag) {
        const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
        const key = Object.keys(tags).find(t => tags[t].name === this.name);
        
        if (key) {
          tag = this.$store.state.tags[key];
          return {
            icon: tag && tag.icon,
            name: this.$t(`tags.${key}.name`),
            description: this.$t(`tags.${key}.description`),
          }
        } else {
          return {}
        }
      } else {
        return {
          icon: tag && tag.icon,
          name: this.$t(`tags.${this.name}.name`),
          description: this.$t(`tags.${this.name}.description`),
        };
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.custom_tooltip {
  opacity: 1 !important;
}
</style>